<template>
  <main class="main">
    <template v-for="(item, index) in payload" >
      <Jumbo v-if="item.type === 'jumbo'" v-bind="item" :key="index"/>
      <JumboStep v-if="item.type === 'jumbo_step'" v-bind="item" :key="index"/>
      <HeroSimple v-if="item.type === 'hero_simple'" v-bind="item" :key="index"></HeroSimple>
      <HeroKpi v-if="item.type === 'hero_kpi'" :kpi="getListItems(item)" v-bind="item" :key="index"></HeroKpi>
      <CheckerBoard v-else-if="item.type === 'damier'" v-bind="item" :reverse="checkReverse(item)" :key="index"/>
      <DtSliderSmall v-else-if="item.type === 'slider'" 
                  style="background-color: #4A4F5F; color: white;" 
                  :key="index"
                  :title="item.title"
                  button-text="Découvrir toutes les ressources"
                  button-link="/bibliotheque"
                  :slides="item.slides"/>
      <JumboCustom v-else-if="item.type === 'jumbo_custom'" 
                  :key="index"
                  v-bind="item" />
      <BackgroundBlock v-else-if="item.type === 'background_block'" 
                      :key="index"
                      v-bind="item" />
    </template>
  </main>
</template>

<script>
import Jumbo from "@/components/Jumbo.vue"
import JumboStep from "@/components/JumboStep.vue"
import CheckerBoard from "@/components/CheckerBoard.vue"
import DtSliderSmall from "@/components/DtSliderSmall.vue";
import HeroSimple from "@/components/HeroSimple.vue";
import HeroKpi from "@/components/HeroKpi.vue";
import JumboCustom from "@/components/JumboCustom.vue";
import BackgroundBlock from "@/components/BackgroundBlock.vue";
export default {
  components: {
    Jumbo, JumboStep, CheckerBoard, DtSliderSmall, HeroSimple, 
    HeroKpi, JumboCustom, BackgroundBlock
  },
  props: {
    payload: {
      type: Array
    }
  },
  methods: {
    checkReverse(item) {
      return item.step%2 ? false : true
    },
    getListItems(item) {
      const kpis = Object.keys(item).filter(f => f.match(/kpi_/))
      const values = kpis.filter(f => f.match(/_value/)).map(m => item[m])
      const texts = kpis.filter(f => f.match(/_text/)).map(m => item[m])
      return {
        values: values,
        texts: texts
      }
    },
  }
}
</script>