<template>
  <div class="jumbo" :style="`background-image:url(${image})`">
    <div class="jumbo-backdrop" :style="backdropCustom ? 'background: linear-gradient(0deg, rgba(37, 55, 70, 1) 0%, rgba(37, 55, 70,0) 100%); z-index: 0;' : ''"/>
    <h1 v-html="title"></h1>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String
    },
    title: {
      type: String
    },
    backdropCustom: {
      type: String,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.jumbo {
  position: relative;
  width: 100%;
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: 300px;
    padding: 24px;
  }

  &-backdrop {
    z-index: -1;
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: $black;
  } 
}

h1 {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin-bottom: 10%;
  color: white;
  font-size: 52px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 32px;
  }

  &::before {
    content: '';
    position: absolute;
    top:0;
    width: 10px;
    height: 100%;
    left: -64px;
    background-color: $secondary;
  }
}
</style>