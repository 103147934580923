<template>
  <div class="jumbo" :style="`background-image:url(${image})`">
    <div class="jumbo-backdrop" :style="backdropCustom ? 'background: linear-gradient(0deg, rgba(37, 55, 70, 1) 0%, rgba(37, 55, 70,0) 100%); z-index: 0;' : ''"/>
    <div class="jumbo-block">
      <h1 v-html="title"/>
      <h2 v-html="text"/>
      <span>{{step}}</span>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String
    },
    title: {
      type: String
    },
    backdropCustom: {
      type: String,
    },
    text: {
      type: String,
    },
    step: {
      type: [String, Number]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.jumbo {
  position: relative;
  width: 100%;
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 64px;

  @media (max-width: 768px) {
    height: 300px;
    padding: 24px;
  }

  &-backdrop {
    z-index: 0;
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    pointer-events: none;    
    background-color: #3B3D3E;
    opacity: .66;
  } 

  &-block {
    position: relative;

    span {
      position: absolute;
      color: rgba(255, 255, 255, .6);
      font-size: 14rem;
      right: calc(100% + 30px);
      top: calc(50% - 100px);
    }
  }
}

h1 {
  position: relative;
  width: 100%;
  max-width: 800px;
  color: white;
  font-size: 52px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.2;
  padding-bottom: 12px;

  @media (max-width: 768px) {
    font-size: 32px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 900;
  color: white;
  max-width: 480px;
}
</style>