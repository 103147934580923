<template>
  <div class="bg-block" :style="{ 'background-image': `url(${image})` }">
    <div class="container">
      <div class="left-block">
        <h2 v-html="title"></h2>
        <p v-html="text"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: { type: String },
    text: { type: String },
    title: { type: String },
    type: { type: String },
  }
}
</script>

<style lang="scss" scoped>

.container {
  height: 100%;
  padding: 32px;
}

.bg-block {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  color: white;
}

.left-block {
  max-width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media(max-width: 768px) {
    max-width: 100%;
  }
}

h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: 24px;
}

p {
  font-size: 15px;
  line-height: 1.5;
  padding-bottom: 24px;
}
</style>