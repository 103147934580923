<template>
  <section>
    <div class="container">
      <p v-html="title" />
      <ul class="kpi">
        <li v-for="(item, index) in kpi.values" :key="index">
          <p class="number" v-html="item"/> 
          <p class="text" v-html="kpi.texts[index]"/>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    kpi: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

.kpi {
  @media (max-width: 1024px) {
    li {
      padding: 0 24px;
    }
  }

  @media(max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
}

section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px;
  background-color: #DEDEDE;
  color: white;

  p, h3, h4, a, svg {
    color: $black;
    text-align: center;
  }

  p {
    font-size: 48px;
    font-weight: 700;
  }
}

ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: $black;
  width: 33%;
  max-width: 100%;
  font-weight: bold;
  padding: 0px 64px;
}

.number {
  font-weight: bold;
  padding: 42px 0 24px;
}

.text {
  font-size: 22px;
  font-weight: 500;
}
</style>