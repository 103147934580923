<template>
  <section class="container">
    <div class="checkerboard" :style="`flex-direction: ${reverse ? 'row-reverse' : 'row'}`">
      <div class="checkerboard-column" :class="background ? 'custom' : ''">
        <div class="checkerboard-block">
          <h2 v-html="title"/>
          <p v-html="text"/>
          <a class="checkerboard-link" v-if="link && text_link" target="_blank" :href="link">{{ text_link}} &nbsp; <img data-v-a28cc308="" src="/img/download.svg" alt=""></a>
          <router-link class="checkerboard-link" v-else-if="internal_link && text_link" :to="internal_link" v-html="text_link"></router-link>
        </div>
        <span class="checkerboard-step" :style="reverse ? 'right: 0' : 'left: 0'">{{step}}</span>
      </div>
      <div class="checkerboard-column">
        <img :src="image" :alt="title">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    reverse: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    text: {
      type: String
    },
    image: {
      type: String
    }, 
    step: {
      type: [String, Number]
    },
    background: {
      type: String
    },
    link: {
      type: String
    },
    internal_link: {
      type: String
    },
    text_link: {
      type: String,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.checkerboard {
  width: 100%;
  display: flex;
  height: 600px;
  max-height: 50vw;

  @media (max-width: 768px) {
    height: auto!important;
    flex-direction: column!important;
    max-height: inherit!important;
  }

  &-link {
    margin-top: 24px;
    display: block;
    text-decoration: none;
    color: $black;
    line-height: 1.5;
    font-weight: 700;

    &:hover {
      color: $green;
    }
    img {
      width: 20px!important;
      margin-left: 12px;
    }
  }

  &-column {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media (max-width: 768px) {
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.custom {
      background: transparent linear-gradient(-90deg, rgba(#DEDEDE, .4) 0%, rgba(#D0D2D3, .4) 39%, rgba($black, .3) 100%) 0% 0% no-repeat padding-box;
    }
  }

  &-step {
    position: absolute;
    bottom: -20px;
    color: #DEDEDE;  
    font-size: 15rem;
    font-weight: bold;
    line-height: .8;
  }

  &-block {
    max-width: 280px;
    z-index: 1;
    padding: 24px 0;

    
    h2 {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.2;
      padding-bottom: 24px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.4;
    }
  }
}
</style>