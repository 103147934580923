<template>
  <div class="jumbo-custom">
    <div class="container container--row">
      <div class="left-block">
        <img :src="image" :alt="image" :style="{ maxHeight: height}">
      </div>
      <div class="right-block">
        <h1 v-if="title" class="title">{{ title }}</h1>
        <h2 v-if="subtitle" v-html="subtitle"/>
        <p v-if="text" v-html="text"></p>
        <a v-if="button" href="#form" style="background-color: #1FDFD5; color: white; max-width: 320px;">{{ button }}</a>
        <p class="ul-title" v-if="list" v-html="list" style="margin-top: 12px;"/>
        <ul v-if="items">
          <li v-for="(item, index) in items" :key="index" v-html="item"></li>
        </ul>
        <p class="sub_text" v-html="sub_text"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: { type: String },
    title: { type: String },
    subtitle: { type: String },
    image: { type: String },
    list: { type: String },
    text: { type: String },
    list_elem_1:{ type: String },
    list_elem_2:{ type: String },
    list_elem_3:{ type: String },
    list_elem_4:{ type: String },
    list_elem_5:{ type: String },
    list_elem_6:{ type: String },
    button: { type: String },
    sub_text: { type: String },
    height: { type: String }
  },
  computed: {
    items() {
      return [this.list_elem_1, this.list_elem_2, this.list_elem_3, this.list_elem_4, this.list_elem_5, this.list_elem_6].filter(f => f)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";

.container--row {
  display: flex;
  flex-direction: row;

  @media(max-width: 768px) {
    flex-direction: column;
  }
}

.jumbo-custom {
  display: flex;
  flex-direction: row;
  background-color: $black;
  color: white;
  padding: 24px;

  img {
    max-height: 450px;
    max-width: 100%;
  }

}

h1 {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin-bottom: 12px;
  color: white;
  font-size: 52px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 32px;
  }

  &::before {
    content: '';
    position: absolute;
    top:0;
    width: 10px;
    height: 100%;
    left: -64px;
    background-color: $secondary;
  }
}

.right-block {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 0 24px 64px;

  @media(max-width: 768px) {
    max-width: 100%;
    width: 100%;
    padding-left: 0;
  }
}

.left-block {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media(max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
}



h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: 24px;
}

.ul-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: 24px;
}

p {
  font-size: 17px;
  line-height: 1.5;
  padding-bottom: 24px;

  &.sub_text {
    padding-top: 12px;
  }
}

ul {
  padding-left: 36px;
  li {
    font-size: 17px;
    line-height: 130%;
    list-style: disc;
    padding-bottom: 4px;
  }
}

a {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  min-height: 36px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  min-width: 140px;
  padding: 0 24px;
  cursor: pointer;
  text-align: center;
}
</style>